'use strict';

/**
 * Initialize Float Labels
 */
function init() {
    let floatInputs = "input[type='date'], input[type='email'], input[type='password'], input[type='search']"
        + ", input[type='tel'], input[type='text'], input[type='url'], select, textarea";
    let $formControls = $(floatInputs);
    $('body').addClass('floating-labels');

    $('form').addClass('floating'); // add glabl utility class to forms for manipluating specific elemtents

    $formControls.each(function () {
        let $currEl = $(this);
        let $label = $(this).parents('.form-group').find('label');
        let required = $(this).parents('.required').length > 0;
        let labelText;

        if (required) {
            labelText = $label.text().trim() + ' * ';
        } else {
            labelText = $label.text().trim();
        }

        $(this).parents('.form-group').addClass('floating-label');

        // move label to follow input
        $label.insertBefore($(this));

        // if no palceholder is set, use label text
        if (!$(this).attr('placeholder')) {
            $(this).attr('placeholder', labelText);
        }

        // detect whether select has a empty "placeholder" value as first option, or an actual value
        if ($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');

            $currEl.parents('.form-group').addClass('floating-select');

            if ($.trim($firstOption.html()).length) {
                $currEl.parents('.form-group').addClass('default-set');
            }
        }
    });

    // select float label functionality
    $('.form-control').on('focus blur change input', function (e) {
        var $currEl = $(this);

        if ($currEl.val().length > 0) {
            $currEl.parents('.form-group').addClass('isValue');
        } else {
            $currEl.parents('.form-group').removeClass('isValue');
        }

        if ($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');
            var labelText = $currEl.siblings('label').text().trim();

            // if first option is empty, then assign the labvel text as "placeholder" option
            if (!$.trim($firstOption.html()).length) {
                $firstOption.text(labelText).attr('label', labelText);
            }

            // if the first value is a "placeholder" apply float label style, if it has a default value apply "selected" style
            if ($firstOption.is(':selected')) { // first option is selected
                if (!$currEl.parents('.default-set').length) { // first option is a placeholder and not a "default"
                    $currEl.parent().removeClass('focused');
                } else { // first option is a "default"
                    $currEl.parent().addClass('focused');
                }
            } else { // first option is not selected
                $currEl.parents('.form-group').toggleClass('focused', ((e.type === 'focus' || this.value.length > 0) && (!$firstOption.is(':selected'))));
            }
        } else { // not a select element
            $currEl.parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }
    }).trigger('blur');
}

module.exports = function () {
    let features = JSON.parse(document.querySelector('body').dataset.features);
    if (features && features.enableFloatLabels) {
        init();
    }
};
