'use strict';

var gtm = {
    getPidValue: function ($el) {
        var pid;
        if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
            pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
        } else if ($('.product-set-detail').length || $('.product-set').length) {
            pid = $($el).closest('.product-detail').find('.product-id').first()
                .text();
        } else {
            pid = $('.product-detail:not(".bundle-item")').data('pid');
        }
        return pid;
    },
    url: '',
    getPageAction: function () {
        return $('.page').data('action');
    },
    requestProductDetails: function (data, targetData, method) {
        $.ajax({
            url: gtm.url,
            method: method,
            data: data,
            dataType: 'json',
            success: function (response) {
                gtm.processResponse(response.productDetails, targetData);
            },
            error: function () {
                console.log('Error in getting GTM data');
            }
        });
    },
    getProductIDS: function (elem) {
        var details = elem;
        var pids = [];
        details.each(function (i) {
            var pid = $(this).data('pid');
            pids.push(pid);
        });
        if (pids.length >= 0) {
            pids = pids.join(',');
        }
        return pids;
    },
    processResponse: function (response, targetData, targetKey, secondKey) {
        try {
            if (response.length > 0) {
                var currTargetData = gtm.targetData[targetData];
                var targetObj = Object.create({});
                // deep clone target object
                $.extend(true, targetObj, currTargetData.data);
                if (typeof (response) === 'string') {
                    // append string response in target property
                    if (typeof (targetKey) !== 'undefined') {
                        gtm.getProperty(targetObj, currTargetData.keys)[targetKey] = response;
                    }
                } else {
                    // push product details
                    response.forEach(function (v) {
                        // clean product details
                        var detail = v;
                        var bonusItems = [];
                        if (v.bonusProductLineItemUUID === 'bonus') {
                            bonusItems = v.bonusProducts;
                            bonusItems.forEach((item) => {
                                if ('productName' in item) {
                                    var bonusItem = productDetail(item);
                                    gtm.getProperty(targetObj, currTargetData.keys).push(bonusItem);
                                }
                            });
                        }

                        // check if raw product details
                        if ('productName' in v) {
                            detail = productDetail(v);
                        }
                        gtm.getProperty(targetObj, currTargetData.keys).push(detail);
                    });
                }
                if (secondKey) {
                    targetObj.ecommerce.checkout.actionField.option = secondKey;
                }

                dataLayer.push(targetObj);
            }
        } catch (e) {
            console.log('GTM: process response');
        }
    },
    init: function () {
        gtm.action.onload();
        var events = gtm.action.events;
        Object.keys(events).forEach(function (key) {
            if (typeof events[key] === 'function') {
                events[key]();
            }
        });
    },
    getProperty: getProperty, // function coming from backend
    targetData: gtmSchema, // object coming from backend
    ajaxHook: function (callback) {
        $(document).ajaxComplete(function (e, request, settings) {
            var response = JSON.parse(request.responseText);
            callback(response);
            $(e.currentTarget).off('ajaxComplete');
        });
        $(document).ajaxError(function (e, request, settings) {
            console.log('Error in getting GTM data');
            $(e.currentTarget).off('ajaxError');
        });
        return false;
    },
    action: {
        onload: function () {
            // add some onload script here
        },
        events: {
            // add all event listener triggered thru click
            showMore: function () {
                $('.container').on('click', '.show-more button', function (e) {
                    e.stopPropagation();
                    var showMoreUrl = $(this).data('url');
                    e.preventDefault();
                    var queryStr = showMoreUrl.split('?')[1];
                    gtm.url = gtmUrls.searchDetails + '?' + queryStr;
                    gtm.requestProductDetails({}, 'PLP', 'GET');
                });
            },
            addToCart: function () {
                var selectedVariantId = '';
                $('body').on('product:beforeAddToCart', function (e, data) {
                    selectedVariantId = gtm.getPidValue($(data));
                });
                $('body').on('product:afterAddToCart', function (e, data) {
                    var items = data.cart.items;
                    var productDetails = [];
                    items.forEach(function (item) {
                        // attach selected item
                        if (item.id === selectedVariantId) {
                            var detail = productDetail(item);
                            var quantity = $('.pdp-main .quantity-input');
                            if (quantity) {
                                detail.quantity = quantity.val();
                            }
                            productDetails.push(detail);
                        }
                    });
                    gtm.processResponse(productDetails, 'AddToCart');
                });
            },
            removeLineItemfromCart: function () {
                $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
                    e.preventDefault();
                    var pid = $(this).data('pid');
                    gtm.ajaxHook(function (req) {
                        gtm.url = gtmUrls.productDetails;
                        gtm.requestProductDetails({ pids: pid }, 'RemoveLineItemfromCart', 'POST');
                    });
                });
            },
            cartUpdateQty: function () {
                $('body').on('change', '.quantity-form > .quantity', function () {
                    var _this = $(this);
                    var preQty = _this.attr('data-pre-select-qty');
                    var newQty = _this.val();
                    var pid = _this.data('pid');
                    var target = 'CartIncreaseQty';
                    if (preQty > newQty) {
                        target = 'CartDecreaseQty';
                    }
                    gtm.ajaxHook(function (response) {
                        var items = response.items;
                        var productDetails = [];
                        items.forEach(function (item) {
                            if (item.id === pid) {
                                var detail = productDetail(item);
                                productDetails.push(detail);
                            }
                        });
                        gtm.processResponse(productDetails, target);
                        _this.attr('data-pre-select-qty', newQty);
                    });
                });
            },
            checkout: function () {
                $('button.submit-shipping').click(function (e) {
                    e.preventDefault();
                    gtm.ajaxHook(function (response) {
                        // add shipping option
                        if (response.order && response.order.shipping[0] && response.order.shipping[0].selectedShippingMethod && response.order.items.items) {
                            gtm.processResponse(response.order.items.items, 'ShippingOption', 'products', response.order.shipping[0].selectedShippingMethod.ID);
                        }

                        // add payment
                        if (response.order && response.order.items) {
                            gtm.processResponse(response.order.items.items, 'Payment');
                        }
                    });
                });

                var submitPayment = document.querySelector('button.submit-payment');
                if (submitPayment) {
                    submitPayment.addEventListener('click', function (e) {
                        e.preventDefault();
                        if (!e.isTrusted) {
                            return;
                        }

                        gtm.ajaxHook(function (response) {
                            if (!response.error) {
                                gtm.processResponse(response.paymentMethod.value, 'PaymentOption', 'option');
                                if (response.order && response.order.items) {
                                    gtm.processResponse(response.order.items.items, 'OrderReview');
                                }
                            }
                        });
                    });
                }
            },
            productVideoClick: function () {
                $(document).on('click', '.carousel .product-video, .installation-content-block .video-modal-trigger', function () {
                    dataLayer.push({
                        event: 'gaEvent',
                        eventCategory: gtmSchema.PDP.data.ecommerce.detail.products[0].category,
                        eventAction: 'installation guide: video',
                        eventLabel: gtmSchema.PDP.data.ecommerce.detail.products[0].id
                    });
                });
            },
            productClick: function () {
                $('.product-tile').on('click', function () {
                    var product = $(this).data('product');
                    if (product) {
                        var productDetails = [];
                        var detail = {
                            id: product.id, name: product.productName, type: product.productType, brand: product.brand, price: product.price
                        };
                        productDetails.push(detail);
                        gtm.processResponse(productDetails, 'ProductClick');
                    }
                });
            }
        } // end
    }
};
$(document).ready(function () { gtm.init(); });
