'use strict';

window.$ = require('jquery');
window.jQuery = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/header'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('core/components/floatLabels'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('core/components/toolTip'));
    processInclude(require('./accelerator/slider'));
    processInclude(require('core/product/quickView'));
    processInclude(require('core/components/instagram'));
    processInclude(require('./components/content'));
    processInclude(require('./optins'));
    processInclude(require('./thirdParty/gtm'));
    processInclude(require('catchsfra/catchInit'));
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
require('core/thirdParty/smartResize');
require('core/thirdParty/hoverIntent');
